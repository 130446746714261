'use client';

import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { TextFieldProps, alpha } from '@mui/material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { forwardRef, useState } from 'react';

type Props = Omit<TextFieldProps, 'type' | 'InputProps'>;

export const PasswordTextField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [visible, setVisible] = useState(false);
  const { ...rest } = props;

  return (
    <TextField
      ref={ref}
      type={visible ? 'text' : 'password'}
      {...rest}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onClick={() => {
                setVisible((p) => !p);
              }}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              size="small"
            >
              {visible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        background: '#F3F5F8',
        borderRadius: '5px',
        '& .MuiInputBase-input': {
          overflow: 'hidden',
        },
        '& fieldset': {
          border: '1px solid #F3F5F8',
        },
        '& .MuiFormLabel-root': {
          color: '#9EA0A2',
        },
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: '#76787A',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#00acff',
          },
        },
        '& .MuiFormLabel-filled': {
          '& + .MuiInputBase-root': {
            '& fieldset': {
              border: `1px solid ${alpha('#000', 0.23)}`,
            },
            ':not(.Mui-focused)': {
              ':hover': {
                '& fieldset': {
                  border: `1px solid #76787A`,
                },
              },
            },
          },
          '& + .Mui-focused': {
            '& fieldset': {
              border: '2px solid #00acff',
            },
          },
        },
      }}
    />
  );
});

PasswordTextField.displayName = 'PasswordTextField';
