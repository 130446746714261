'use client';

import { type UserAccount } from '@ocode/domain';
import { BrowserStorageWrapper, SingleValueRxEmitter } from '@ocodelib/ex-util';
import { DummyStorage } from '@repo-shared-base/utils/DummyStorage';
import type { lib } from 'crypto-js';
import AES from 'crypto-js/aes';
import encUtf8 from 'crypto-js/enc-utf8';
import { config } from '../config/index';

const AUTH_REFRESH_TOKEN_STORAGE_KEY = config.storageKeys.authRefreshToken;
const AUTH_TOKEN_STORAGE_KEY = config.storageKeys.authToken;
const AUTH_PROFILE_STORAGE_KEY = config.storageKeys.profile;
const AI_TOKEN_STORAGE_KEY = config.storageKeys.aiToken;
const BROWSER_ID_STORAGE_KEY = config.storageKeys.browserId;

const secret = '836D96123430DD58429D2751AC1BDBBC';

class AESEncoder {
  private key: lib.WordArray;
  private iv: lib.WordArray;

  constructor(secret: string) {
    this.key = encUtf8.parse(secret);
    this.iv = encUtf8.parse(secret.substring(0, 16));
  }

  enc = (plaintext: string): string => {
    const encrypted = AES.encrypt(plaintext, this.key, {
      iv: this.iv,
    });
    return encrypted.toString().toString();
  };

  dec = (encoded: string): string => {
    return AES.decrypt(encoded, this.key, { iv: this.iv }).toString(encUtf8);
  };
}

const aes = new AESEncoder(secret);

const serialize = <T>(value: T): string => {
  return aes.enc(JSON.stringify(value));
};

const deserialize = <T>(value: string): T => {
  return JSON.parse(aes.dec(value)) as T;
};

// const serializeDebug = <T>(value: T): string => {
//   console.log("serialize", value, "result=", JSON.stringify(value));
//   return JSON.stringify(value);
// };

// const deserializeDebug = <T>(value: string): T => {
//   // console.log("deserialize", value, "result=", JSON.parse(value));
//   return JSON.parse(value);
// };

const dummyLocalStorage = new DummyStorage();

function getLocalStorage(): Storage {
  if (typeof window !== 'undefined') {
    return localStorage;
  }

  return dummyLocalStorage;
}

/**
 * 브라우저 ID 스토리지
 */
export const BrowserIdStorage = new BrowserStorageWrapper<string>({
  storage: getLocalStorage,
  storageKey: BROWSER_ID_STORAGE_KEY,
  serialize,
  deserialize,
});

// BrowserIdStorage.debug = BROWSER_ID_STORAGE_KEY;

/**
 * 사용자 프로필 정보 스토리지
 */
export const LoginProfileStorage = new BrowserStorageWrapper<UserAccount>({
  storage: getLocalStorage,
  storageKey: AUTH_PROFILE_STORAGE_KEY,
  serialize,
  deserialize,
});
// LoginProfileStorage.debug = AUTH_PROFILE_STORAGE_KEY;

/**
 * 인증 토큰 저장소
 */
export const LoginTokenStorage = new BrowserStorageWrapper<string>({
  storage: getLocalStorage,
  storageKey: AUTH_TOKEN_STORAGE_KEY,
  serialize,
  deserialize,
});
// LoginTokenStorage.debug = AUTH_TOKEN_STORAGE_KEY;

/**
 * AI 토큰 저장소
 */
export const AiTokenStorage = new BrowserStorageWrapper<string>({
  storage: getLocalStorage,
  storageKey: AI_TOKEN_STORAGE_KEY,
  serialize,
  deserialize,
});

/**
 * 리프레시 토큰 저장소
 */
export const LoginRefreshTokenStorage = new BrowserStorageWrapper<string>({
  storage: getLocalStorage,
  storageKey: AUTH_REFRESH_TOKEN_STORAGE_KEY,
  serialize,
  deserialize,
});

/**
 * 프로필 정보를 로드했다는 이벤트
 */
export const LoginProfileLoadedEvent = new SingleValueRxEmitter<UserAccount>();
