import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import { type CoppiaPub } from '@ocode/domain';
import { isEnterKeyEvent, isEnterOrTabKeyEvent, requestFocusSelector } from '@ocodelib/ui-common';
import { isBlank, isNotBlank, trim } from '@ocodelib/util/string';
import { useLocale } from 'next-intl';
import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { LoginProfileLoadedEvent } from '../../../auth/authentication';
import { CustomTextField } from '../../../components/CustomTextField';
import { PasswordTextField } from '../../../components/PasswordTextField';
import { useCoppiaPub } from '../../../provider/CoppiaPubProvider';
import { useApi } from '../../../provider/useApi';
import { routerPush } from '../../../router';
import { handleError } from '../../../util/handle-error';

interface InputData {
  loginId: string;
  pw: string;
}

interface Props {
  onClose: React.MouseEventHandler;
  onLoginSuccess: () => void;
}

export function LoginForm(props: Props) {
  const { onClose, onLoginSuccess } = props;
  const locale = useLocale();
  const rootRef = useRef<HTMLElement>();
  const api = useApi();
  const coppia = useCoppiaPub();
  const [inputData, setInputData] = useState<Partial<InputData>>({});
  const [loading, setLoading] = useState(false);
  const [loginProcessing, setLoginProcessing] = useState(false);
  const [idSaveChecked, setIdSaveChecked] = useState(false);

  const updateInput = (part: Partial<InputData>) => {
    setInputData((prev) => ({ ...prev, ...part }));
  };

  const focus = useCallback((selector: string) => {
    requestFocusSelector(rootRef.current, selector, 0);
  }, []);

  /**
   * 서버 로그인 실행
   */
  const doLogin = useCallback(
    async (loginId: string, password: string, coppia: CoppiaPub): Promise<boolean> => {
      setLoading(true);
      try {
        // const publicKey = await CoppiaUtil.loadPublicKey(coppia.pubKey);
        // 임시로 비번 암호안함
        // const pw = await CoppiaUtil.encryptText(publicKey, password);
        // const cid = coppia.cid,
        const { profile: userProfile } = await api.auth.signIn({
          cid: 0,
          loginId,
          pw: password,
        });
        LoginProfileLoadedEvent.emit(userProfile);
        toast.success('로그인 되었습니다', {
          autoClose: 1500,
          position: 'top-center',
        });
        return true;
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
      return false;
    },
    [api],
  );

  const validateAndLogin = () => {
    // if (!coppia) {
    //   console.warn("coppia is null");
    //   return;
    // }

    setLoginProcessing(true);
    const loginId = trim(inputData.loginId);
    const pw = trim(inputData.pw);
    if (isBlank(loginId)) {
      toast.warn('사용자 ID를 입력해주세요');
      setLoginProcessing(false);
      return false;
    }

    if (isBlank(pw)) {
      toast.warn('비밀번호를 입력해주세요');
      setLoginProcessing(false);
      return;
    }

    doLogin(loginId, pw, coppia as any)
      .then((succ) => {
        if (succ) {
          onLoginSuccess();
        }
      })
      .finally(() => {
        setLoginProcessing(false);
      });
  };

  // 로그인 버튼 클릭
  const handleClickLogin = () => {
    if (loginProcessing) return;
    validateAndLogin();
  };

  // 비밀번호 찾기 버튼 클릭
  const handleClickPwFind = () => {
    //
  };

  // 가입 버튼 클릭
  const handleClickJoin = () => {
    routerPush(locale, '/join');
  };

  const { loginId, pw } = inputData;
  return (
    <>
      <DialogContent
        className="LoginForm-root"
        ref={rootRef}
        sx={{ pt: 3, pb: 7, px: 5, position: 'relative' }}
      >
        <DialogTitle
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: 34,
            px: 0,
            py: 0,
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: -10,
              right: -30,
            }}
            onClick={onClose}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mb: 5,
            }}
          >
            <Typography
              sx={{
                position: 'relative',
                display: 'inline',
                fontSize: '1.8rem',
                fontWeight: 800,
                // fontFamily: HakgyoansimKkwabaegiOTFR.style,
              }}
            >
              Login
              <Box
                className="LoginForm-logo"
                sx={{
                  position: 'absolute',
                  top: -5,
                  left: 80,
                  width: 24,
                  height: 24,
                  '& img': {
                    width: '100%',
                    height: '100%',
                  },
                }}
              >
                <img src="/_static/images/login_dolphin.png" alt="" />
              </Box>
            </Typography>

            <Typography
              sx={{
                mt: 1,
                fontSize: '0.8rem',
                fontWeight: 500,
                color: '#4E5052',
              }}
            >
              회원 서비스 이용을 위해 로그인해 주세요.
            </Typography>
          </Box>
          <Box>
            <CustomTextField
              fullWidth
              label="아이디 입력"
              variant="outlined"
              disabled={loading || loginProcessing}
              size="medium"
              onChange={(e) => {
                updateInput({ loginId: e.target.value });
              }}
              value={loginId ?? ''}
              className="LoginForm-loginInput LoginForm-loginId"
              onKeyDown={(e) => {
                if (isNotBlank(loginId) && isEnterOrTabKeyEvent(e)) {
                  e.preventDefault();
                  focus('.LoginForm-pw input');
                }
              }}
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              '& .MuiInputBase-input': {
                overflow: 'hidden',
              },
              '& fieldset': {
                border: '1px solid #F3F5F8',
              },
              '& .MuiFormLabel-root': {
                color: '#9EA0A2',
              },
            }}
          >
            <PasswordTextField
              className="LoginForm-loginInput LoginForm-pw"
              label="비밀번호 입력"
              value={pw ?? ''}
              onChange={(e) => {
                updateInput({ pw: e.target.value });
              }}
              onKeyDown={(e) => {
                if (isNotBlank(pw) && isEnterKeyEvent(e)) {
                  e.preventDefault();
                  focus('.LoginForm-loginBtn');
                  handleClickLogin();
                }
              }}
            />
          </Box>
          <FormControlLabel
            sx={{
              mt: 1,
              '& .MuiFormControlLabel-label': {
                fontSize: '0.8rem',
                color: '#76787A',
              },
            }}
            control={
              <Checkbox
                checked={idSaveChecked}
                onChange={(e) => {
                  setIdSaveChecked(e.target.checked);
                }}
                color="primary"
                size="small"
              />
            }
            label="아이디 저장"
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              mt: 1,
            }}
          >
            <Button
              className="LoginForm-loginBtn"
              variant="contained"
              size="large"
              color="primary"
              disabled={loading || loginProcessing}
              onClick={handleClickLogin}
              sx={{
                mt: 3,
                width: 150,
              }}
            >
              로그인
            </Button>
            <Box
              sx={{
                mt: 1,
                color: '#363636',
                display: 'flex',
                alignItems: 'center',
                flexShrink: 0,
                '& .MuiDivider-root': {
                  height: 12,
                  alignSelf: 'center',
                  my: 0,
                  mx: 1,
                },
              }}
            >
              <Button disabled={loading} onClick={handleClickPwFind} style={{ opacity: 0.7 }}>
                비밀번호 찾기
              </Button>
              <Divider orientation="vertical" flexItem />
              <Button disabled={loading} onClick={handleClickJoin} style={{ opacity: 0.7 }}>
                회원가입
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={onClose}>닫기</Button>
      </DialogActions> */}
    </>
  );
}
