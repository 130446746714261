/* eslint-disable @typescript-eslint/prefer-promise-reject-errors */
import type { AppError } from '@ocodelib/api-common';
import { isCanceledError, isLoginError } from '@ocode/domain';
import type { AxiosInstance } from 'axios';
import axios from 'axios';
import { LoginRefreshTokenStorage, LoginTokenStorage } from '../auth/authentication';

const logger = console;

const DEBUG = true;

type TokenCallback = (token?: string) => void;

export class ApiTokenRefresher {
  #subscribers = [] as TokenCallback[];

  #isRefreshing = false;

  constructor(public readonly apiBaseURL: string) {
    //
  }

  #subscribeTokenRefresh = (callback: TokenCallback) => {
    this.#subscribers.push(callback);
  };

  #onRefreshed = (token: string) => {
    this.#subscribers.forEach((callback) => {
      callback(token);
    });
    this.#subscribers = [];
  };

  #onRefreshedFailed = () => {
    this.#subscribers.forEach((callback) => {
      callback(undefined);
    });
    this.#subscribers = [];
  };

  checkAuthByRefreshToken = async (axiosInstance: AxiosInstance, error: any) => {
    if (!error) return;
    if (isCanceledError(error)) {
      return; //Promise.reject(error);
    }

    const originalRequest = error.config;
    const refreshToken = LoginRefreshTokenStorage.value;
    // console.log('ApiTokenRefresher.checkAuthByRefreshToken()', {
    //   retry: originalRequest?._retry,
    //   status: error.response?.status,
    //   refreshToken,
    //   originalRequest,
    // });

    const statusCode = error?.response?.status;

    // 리프레시 토큰이 없으면 에러 리턴
    if (!refreshToken) {
      // console.log('리프레시 토큰이 없습니다');
      return Promise.reject(error);
    }

    if ((statusCode === 401 || statusCode === 403) && !originalRequest._retry) {
      if (!this.#isRefreshing) {
        console.log('리프레시를 시도합니다');
        this.#isRefreshing = true;
        try {
          const { data } = await axios.post(
            this.apiBaseURL + '/p/admin/auth/refresh-token',
            {
              refreshToken,
            },
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            },
          );

          const responseData = data as
            | {
                success: true;
                body: {
                  token: string;
                  refreshToken: string;
                };
              }
            | {
                success: false;
                errorCode: string;
              };

          if (!responseData.success) {
            // throw createAppError(responseData.errorCode);
            return Promise.reject(error);
          }

          const result = responseData.body as {
            token: string;
            refreshToken: string;
          };
          LoginTokenStorage.value = result.token;
          LoginRefreshTokenStorage.value = result.refreshToken;
          this.#isRefreshing = false;

          console.log('XXXX 리프레시 성공', result);
          setTimeout(() => {
            // 토큰 준비되었음 알림
            this.#onRefreshed(result.token);
          });

          // 현재요청에 토큰을 설정하여 재시작
          originalRequest.headers.setAuthorization(`Bearer ${result.token}`);
          return axiosInstance(originalRequest);
        } catch (_error) {
          // refresh token 실패
          console.log('refresh token failed', _error);
          LoginTokenStorage.clear();
          LoginRefreshTokenStorage.clear();
          this.#onRefreshedFailed();
          return Promise.reject(error);
        }
      }

      return new Promise((resolve, reject) => {
        // console.log('XXXX 리프레시를 기다립니다', originalRequest);
        this.#subscribeTokenRefresh((token) => {
          if (token) {
            // console.log('XXXX 요청 재전송 ', originalRequest);
            originalRequest.headers.setAuthorization(`Bearer ${token}`);
            // originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axiosInstance(originalRequest));
          } else {
            // console.log('XXXX 리프레시 실패하여 재전송 포기', originalRequest);
            reject(error);
          }
        });
      });
    }

    return Promise.reject(error);
  };

  checkAuthTokenClear = (error: AppError) => {
    if (isCanceledError(error)) {
      return;
    }

    // console.log("ApiTokenRefresher.checkAuthTokenClear()", error);
    if (isLoginError(error)) {
      if (DEBUG) {
        logger.log('ApiTokenRefresher.checkAuthTokenClear()', error);
      }
      LoginTokenStorage.clear();
      LoginRefreshTokenStorage.clear();
    }
  };
}
