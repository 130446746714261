import { type UserAccount } from '@ocode/domain';

export class LoginUser {
  readonly profile: UserAccount | undefined;
  constructor(profile: UserAccount | undefined) {
    this.profile = profile;
  }

  get loginAccountId() {
    return this.profile?.accountId;
  }

  get loginId() {
    return this.profile?.loginId;
  }

  get isAdmin(): boolean {
    return this.profile?.loginId === 'master';
  }

  get isLoggedIn(): boolean {
    return !!this.profile;
  }

  /**
   * 로그인 프로필은 없지만 로그인 토큰은 존재할 수 있음
   * isLoggedOut=true라도, 로그인 프로필을 로드하는 중일 수도 있음
   */
  get isLoggedOut(): boolean {
    return !this.profile;
  }
}
